<template lang="pug">
.Login
  section.App.text-center.position-relative
    .d-flex.align-items-center.justify-content-between.backButton.position-relative(@click="goBack" style="z-index: 3;")
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.ml-3
    figure.containerImg
      img(src="@/assets/mobileFSFB/helpingBoy.png" alt="helpingBoy").h-100.containerImg-img.w-100
      .containerImg-tape
    .w-100.d-flex.justify-content-center.columnClass
      img(src="@/assets/mobileFSFB/logoFSFB.png" alt="logoFSFB").logoAuthLogin.mx-auto
      h3.yourTurnLabel {{ selectedArea ? selectedArea.label : "" }}
    .boxText(v-show="modeWelcome")
      .containerText.containerTextFirst
        h2.containerText-title.mb-0 Bienvenido a
        p.containerText-desc.m-0(v-if="areaCustom") {{areaCustom.label}}
      .containerText
        p(v-if="areaCustom && areaCustom.labelEnglish").containerText-desc.containerText-lg.mt-0.text-start
          | Welcome to
          span.d-block(v-if="areaCustom") {{areaCustom.labelEnglish}}
        p.text-center.containerText-reserved
          strong.text-derechos.d-block.pt-4.mb-0.m-0.mt-5 Todos los derechos reservados
          strong.text-derechos.d-block.mt-0.pt-1.m-0 © Fundación Santa Fe de Bogotá 2022
    .text-start.boxText.boxText2.mx-auto(v-show="!modeWelcome")
      .boxContent.pb-3
        h2.titleFSFBM Ingreso de datos
        p.desc2
          | Digita los datos de la persona que
          span.ml-1 va a acceder al servicio
      form(
        @submit.prevent="onContinue"
      ).boxWhite.mx-auto.px-4.pt-2.pb-3.d-block.w-100
        p.fonterBlue.mb-1.pt-1 Tipo de documento
        .form-select-container.mb-3.position-relative
          b-form-select.Input-text.p-2.pr-5(
            v-model="models.TipoIdentificacion"
            :options="optionsMapped"
            size="sm"
            autocomplete="off"
            required
          ).position-relative
          svg.mr-2(width='21' height='21' viewbox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg').z-negative-2
            path(d='M10.6375 4.23521C11.9577 4.23521 13.2482 4.62851 14.3459 5.36536C15.4436 6.10222 16.2992 7.14952 16.8044 8.37486C17.3096 9.6002 17.4418 10.9485 17.1842 12.2493C16.9267 13.5501 16.291 14.745 15.3574 15.6829C14.4239 16.6207 13.2346 17.2594 11.9397 17.5181C10.6449 17.7769 9.3028 17.6441 8.0831 17.1365C6.86341 16.629 5.82092 15.7694 5.08746 14.6667C4.354 13.5639 3.96252 12.2674 3.96252 10.9411C3.96252 9.16259 4.66578 7.45692 5.91758 6.19932C7.16938 4.94173 8.86719 4.23521 10.6375 4.23521Z' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M10.6376 12.2815L12.4209 10.49' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            path(d='M8.85413 10.49L10.6374 12.2815' stroke='#788591' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
        p.fonterBlue.mb-1.pt-1 Número de documento
        input(
          placeholder="Ej: 105456783"
          v-model="models.NumeroIdentificacion"
          ref="idNumberInput"
          required
        ).Input-text.w-100.p-2.inputDoc
        button(
          type="submit"
          :disabled="!models.NumeroIdentificacion"
        ).buttonForm.d-block.w-100.mt-4
          span(v-if="!charging") Continuar
          span(v-else)
            MiniSpinner
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",

  components: {
    MiniSpinner: () => import("./MiniSpinner.vue")
  },

  data() {
    return {
      modeWelcome: false,
      models: {
        NumeroIdentificacion: "",
        TipoIdentificacion: "CC"
      }
    };
  },

  computed: {
    ...mapState({
      options: state => state.mobileFSFB.tipide,
      error: state => state.mobileFSFB.error,
      charging: state => state.mobileFSFB.charging,
      sites: state => state.mobileFSFB.sites,
      selectedArea: state => state.mobileFSFB.selectedArea
    }),

    toSend() {
      let sede = this.$route.params.code;
      return {
        ...this.models,
        CodigoOficina: sede + "?area=" + this.$route.query.area
      };
    },

    optionsMapped() {
      return (
        this.options?.map(option => ({
          text: `${option.value} - ${option.text}`,
          value: option.value
        })) || []
      );
    },

    areaCustom() {
      try {
        return this.sites[0].customAreas[this.$route.query.area];
      } catch (error) {
        return "";
      }
    }
  },

  methods: {
    ...mapActions({
      goToView: "mobileFSFB/goToView",
      changeDocInfo: "mobileFSFB/changeDocInfo",
      authenticate: "mobileFSFB/authenticate"
    }),

    goBack() {
      this.$emit("modeBack");
    },

    async onContinue() {
      this.changeDocInfo(this.models);
      await this.authenticate(this.toSend);
      // this.goToView({ view: "ConfirmDataAuth" });
    }
  }
};
</script>

<style lang="scss" scoped>
.Login {
  height: 100vh;
}

.App {
  position: relative;
  height: 100%;
}

.containerImg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.containerImg-tape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    359.49deg,
    var(--color-bg-dark) 2.94%,
    rgba(32, 40, 87, 0.661458) 56.73%,
    rgba(33, 30, 23, 0) 99.46%
  );
}

.containerText {
  position: relative;
  font-weight: 500;
  line-height: 42px;
  color: var(--color-white);
}

.containerTextFirst {
  border-left: 8px solid var(--color-primary);
  text-align: start;
  padding-left: 15px;
  padding-bottom: 0px;
  margin-left: 25px;
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 0;
}

.containerTextFirst p {
  padding-bottom: 15px;
}

.containerText-title {
  font-size: 36px;
  font-weight: 800;
}

.containerText-desc {
  line-height: 25px;
}

.containerText-lg {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 82px;
}

.text-derechos {
  width: 100%;
  // max-width: 233px;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
}

.boxText {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.desc2 {
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-white);
}

.boxWhite {
  width: 312px;
  border-radius: 22px;
  background-color: var(--color-white);
}

.fonterBlue {
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--color-primary);
}

.Input-text {
  background: transparent;
  border: 2px solid var(--color-border-white);
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-gray);
  z-index: 1;
}

.form-select-container svg {
  background: var(--color-white);
  right: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.buttonForm {
  height: 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  border-radius: 16px;
  transition: 0.5s all;
  background: var(--color-radiant);
  color: var(--color-white);
}

.buttonForm:disabled {
  color: #dedede;
  background: none;
}

.boxText2 {
  width: 100%;
  padding: 0 21px;
  padding-bottom: 119px;
  overflow: auto;
  position: relative;
}

.boxContent {
  width: 312px;
}

.containerImg-img {
  object-fit: cover;
}

.svgBanderas {
  margin-bottom: -4px;
}

.logoAuthLogin {
  top: 0;
  margin-top: 0;
  margin-bottom: 1%;
  position: relative;
}

.z-negative-2 {
  z-index: 0;
}

.inputDoc {
  letter-spacing: 5px;
}

.backButton {
  padding-top: 21px;
}

.containerText-desc.containerText-lg {
  padding-bottom: 29px;
  padding-left: 50px;
}

.yourTurnLabel {
  font-family: var(--font-secondary);
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  padding-top: 26.12px;
  margin-bottom: 12.12px;
}

.columnClass {
  flex-direction: column;
  z-index: 9;
  margin-bottom: 32%;
}

.columnClass h3 {
  z-index: 9;
}
</style>
